import React, { useEffect, useState, useMemo } from "react";
import styles from "./addnew.module.sass";
import Card from "../../../../components/Card";
import cn from "classnames";
import Icon from "../../../../components/Icon";
import CreateNewProduct from "../../Invoices/NewInvoice/Modals/CreateNewProduct";
import Search from "../Search";
import Dropdown from "../../../../components/Dropdown_c";
import Line from "../Lines";
import CheckboxWithText from "../checkboxWithText";
import SuccessModal from "../successModal";
import CountrySelectDropdown from "../../../../components/multipleDropdown";
import countryList from "react-select-country-list";
import {
  addPaymentLink,
  searchProduct,
  searchProductPaymentLink,
} from "../../../../utils/apiCallHanlder";
import Spinner from "../../../../utils/spinner";
import { toast, ToastContainer } from "react-toastify";
import {
  toastConfiguration,
  trackIntercomEvent,
} from "../../../../utils/utils";

const dropdownValues = [
  {
    display: "Products or Subscription",
    text: "Products or Subscription",
    value: "standard",
  },
  {
    display: "Customer choose what to pay",
    text: "Customer choose what to pay",
    value: "customer_choose",
  },
];

const AddNewPaymentLink = ({
  className,
  showAddPaymentLink,
  setShowAddPaymentLink,
  customerId,
}) => {
  const [visible, setVisible] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [productName, setProductName] = useState("");
  const [showNewProductModal, setShowNewProductModal] = useState(false);
  const [addAnotherProduct, setAddAnotherProduct] = useState(false);
  const [showAddAnotherProductBtn, setShowAddAnotherProductBtn] =
    useState(true);

  const [automaticTaxCheckBox, setAutomaticTaxCheckbox] = useState(false);
  const [customerAddressCheckbox, setCustomerAddressCheckbox] = useState(false);
  const [phoneNoCheckbox, setPhoneNumberCheckbox] = useState(false);
  const [billingAddressCheckbox, setBillingAddressCheckbox] = useState(false);
  const [shippingAddressCheckbox, setShippingAddressCheckbox] = useState(false);
  const [dropdownValue, setDropDownValue] = useState(dropdownValues[0]?.value);
  const [searchNamesProducts, setSearchNamesProducts] = useState([]);
  const [addLoading, setAddLoading] = useState(false);
  const [addPaymentLinkLoading, setAddPaymentLinkLoading] = useState(false);
  const [newlyCreatedPaymentLink, setNewlyCreatedPaymentLink] = useState({});
  const [lineId, setLineId] = useState(0);

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);

  const [lines, setLines] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const countries = useMemo(() => countryList().getData(), []);

  useEffect(async () => {
    setSearchLoading(true);
    await getProducts("");
    setSearchLoading(false);
  }, [dropdownValue]);

  const getProducts = async (searchString) => {
    const { data } = await searchProductPaymentLink(
      searchString,
      dropdownValue
    );
    if (data) {
      setSearchNamesProducts(data?.productList);
    }
  };

  const productSelectHandler = async (product) => {
    for (let i = 0; i < lines?.length; i++) {
      if (lines[i]?.productId === product?.id) {
        toast.error("Product already added", toastConfiguration);
        return;
      }
    }

    setLineId((prevLineId) => prevLineId + 1);
    const lineObject = {
      lineId: lineId + 1,
      productId: product?.id,
      priceId: product?.priceId,
      pricingModel: product?.pricingModel,
      name: product?.name,
      quantity: 1,
      description: product?.description,
      isCustomerAdjustQuantity: false,
      priceValue: product?.priceValue,
    };
    lines.push(lineObject);
    setVisible(false);
    setAddLoading(true);

    setProductName("");
    setAddAnotherProduct(false);
    setShowAddAnotherProductBtn(true);
    setSearchString("")
    setAddLoading(false);
  };

  const createLinkButtonClickHandler = async () => {
    if (dropdownValue !== "customer_choose") {
      for (let i = 0; i < lines?.length; i++) {
        if (lines[i]?.priceValue === 0) {
          toast.error("Price of product cannot be zero", toastConfiguration);
          return;
        }
      }
    }
    if (addPaymentLinkLoading) {
      return;
    }
    setAddPaymentLinkLoading(true);
    const addPaymentLinkPayload = {
      type: dropdownValue,
      shippingCountry: selectedCountries,
      isRequirePhoneNumber: phoneNoCheckbox,
      isCollectTaxAuto: automaticTaxCheckBox,
      isCollectCustomerAddress: customerAddressCheckbox,
      isCollectCustomerBillingAddress: billingAddressCheckbox,
      isCollectCustomerBillingAndShippingAddress: shippingAddressCheckbox,
      lineItems: lines,
    };

    const { data, error } = await addPaymentLink(addPaymentLinkPayload);

    if (data) {
      setNewlyCreatedPaymentLink(data.invoice);
      setShowSuccessModal(true);
      trackIntercomEvent("paymentlink-created");
    } else {
      toast.error(error, toastConfiguration);
    }
    setAddPaymentLinkLoading(false);
  };

  return (
    <>
      {showNewProductModal && (
        <CreateNewProduct
          showNewProductModal={showNewProductModal}
          searchString={searchString}
          setShowNewProductModal={setShowNewProductModal}
          productSelectHandler={productSelectHandler}
          showProduct={() => {
            setShowNewProductModal(false);
          }}
        />
      )}
      {showSuccessModal ? (
        <SuccessModal
          visible={showSuccessModal}
          paymentObject={newlyCreatedPaymentLink}
          setShowAddPaymentLink={setShowAddPaymentLink}
          setVisible={setShowSuccessModal}
        />
      ) :
        <div className={styles.detailsWrap} style={showNewProductModal ? { display: 'none' } : { width: "320px" }}>
          <ToastContainer />

          {lines?.length < 1 ? (
            <Card
              className={cn(styles.card, className)}
              title='Payment Link'
              border
            /* classTitle="title-yellow" */
            >
              <Search
                className={styles.search}
                inputPlaceholder='Search or add'
                addNewItem={() => setShowNewProductModal(true)}
                addNewBtnTxt='Create new Product'
                visible={visible}
                searchLoading={searchLoading}
                setSearchLoading={setSearchLoading}
                addLoading={addLoading}
                productName={productName}
                setVisible={setVisible}
                getProducts={getProducts}
                productSelectHandler={productSelectHandler}
                searchString={searchString}
                setSearchString={setSearchString}
                searchNames={searchNamesProducts}
                setSearchNamesProducts={setSearchNamesProducts}
              />
            </Card>
          ) : (
            <Card
              className={cn(styles.card, className)}
              title='Payment Link'
              border
            /* classTitle="title-yellow" */
            >

              <div className={styles.detailsTxt}>Products</div>
              {lines?.map((x, index) => (
                <div key={index}>
                  <Line
                    x={x}
                    index={index}
                    lines={lines}
                    dropdownValue={dropdownValue}
                    //setShowProduct={setShowActionBtn}
                    setLines={setLines}
                  />
                </div>
              ))}
              {showAddAnotherProductBtn ? (
                dropdownValue === "customer_choose" &&
                  lines.length === 1 ? null : (
                  <button
                    onClick={() => {
                      setAddAnotherProduct(true);
                      setShowAddAnotherProductBtn(false);
                    }}
                    className={styles.addProductBtn}
                  >
                    Add another product
                  </button>
                )
              ) : null}
              {addAnotherProduct ? (
                <>
                  <Search
                    className={styles.search}
                    inputPlaceholder='Search or add'
                    addNewItem={() => setShowNewProductModal(true)}
                    addNewBtnTxt='Create new Product'
                    visible={visible}
                    addLoading={addLoading}
                    productName={productName}
                    setVisible={setVisible}
                    getProducts={getProducts}
                    searchLoading={searchLoading}
                    setSearchLoading={setSearchLoading}
                    productSelectHandler={productSelectHandler}
                    searchString={searchString}
                    setSearchString={setSearchString}
                    searchNames={searchNamesProducts}
                  />
                </>
              ) : null}
              <div className={styles.optionsText}>Options</div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: 5,
                  marginBottom: 20,
                }}
              >
                <CheckboxWithText
                  value={automaticTaxCheckBox}
                  setValue={setAutomaticTaxCheckbox}
                  text={"Collect tax automatically"}
                  showTooltip={false}
                />
                <CheckboxWithText
                  value={customerAddressCheckbox}
                  text={"Collect customers addresses"}
                  showTooltip={false}
                  onChange={() => {
                    setCustomerAddressCheckbox(!customerAddressCheckbox);
                    if (!customerAddressCheckbox && !shippingAddressCheckbox) {
                      setBillingAddressCheckbox(true);
                    } else {
                      setBillingAddressCheckbox(false);
                    }
                  }}
                />
                {customerAddressCheckbox ? (
                  <div style={{ marginLeft: 30 }}>
                    <CheckboxWithText
                      value={billingAddressCheckbox}
                      text={"Billing address only"}
                      showTooltip={false}
                      onChange={() => {
                        setBillingAddressCheckbox(!billingAddressCheckbox);
                        setShippingAddressCheckbox(!shippingAddressCheckbox);
                      }}
                    />
                    <CheckboxWithText
                      value={shippingAddressCheckbox}
                      text={"Billing and shipping address"}
                      showTooltip={false}
                      onChange={() => {
                        setBillingAddressCheckbox(!billingAddressCheckbox);
                        setShippingAddressCheckbox(!shippingAddressCheckbox);
                      }}
                    />
                    <div style={{ marginTop: 10 }}>
                      {shippingAddressCheckbox ? (
                        <CountrySelectDropdown
                          options={countries}
                          showSearch={"true"}
                          selectedCountries={selectedCountries}
                          setSelectedCountries={setSelectedCountries}
                        />
                      ) : null}
                    </div>
                  </div>
                ) : null}
                <CheckboxWithText
                  value={phoneNoCheckbox}
                  setValue={setPhoneNumberCheckbox}
                  text={"Require phone number"}
                  showTooltip={false}
                />
              </div>
              {lines?.length > 0 ? (
                <button
                  onClick={createLinkButtonClickHandler}
                  className={cn("button", styles.sendBtn)}
                  style={{ width: 170 }}
                >
                  {addPaymentLinkLoading ? (
                    <Spinner size='20' color='white' />
                  ) : (
                    <>
                      <span>Create Link</span>
                    </>
                  )}
                </button>
              ) : null}
            </Card>
          )}
        </div >
      }
    </>
  );
};

export default AddNewPaymentLink;
