import React, { useEffect, useState, useMemo } from "react";
import styles from "./NewCard.module.sass";
import Card from "../../../../components/Card";
import cn from "classnames";
import Icon from "../../../../components/Icon";
import Checkbox from "../../../../components/Checkbox";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import {
  getConnectStatus,
  toastConfiguration,
  trackIntercomEvent,
} from "../../../../utils/utils";
import CardOwners from "./CardOwners";
import Search from "./Search";
import countryList from "react-select-country-list";
import CardDetails from "../CardDetails";
import DropdownState from "../../../../components/Dropdown_c";
import { useHistory } from "react-router";
import {
  createCard,
  getCardHolders,
  cardStatusChange,
} from "../../../../utils/apiCallHanlder";
import Dropdown from "../../../../components/cardholderDropdown";
import SimpleDropdown from "../../../../components/Dropdown";
import TextInput from "../../../../components/TextInput";
import Spinner from "../../../../utils/spinner";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { FetchAddress } from "../../../../components/FetchAddress";

let haveDropdownsData = false;
let states = [""];

const NewCard = ({
  className,
  setShowNewCard,
  setAddCard,
  setShowCards,
  isVirtual,
  showCardDetails,
  setShowCardDetails,
  isPhysical,
  cardOwners,
  setIsPhysical,
  setIsVirtual,
  setCardOwners,
  NextClickHandler,
}) => {
  if (!haveDropdownsData) {
    const customerLookupdata = JSON.parse(localStorage.getItem("lookupData"));
    let data = null;
    if (customerLookupdata) {
      data = customerLookupdata?.data;

      states = [
        {
          display: "Select State",
          text: "Select State",
          value: "select",
        },
      ].concat(data.US_States);

      haveDropdownsData = true;
    }
  }

  let options = useMemo(() => countryList().getData(), []);

  const [visible, setVisible] = useState(false);
  const [open, setopen] = useState(true);
  const [searchString, setSearchString] = useState("");
  const [cardHoldersArray, setCardHolderArray] = useState([]);
  const [selectedCardHolderName, setSelectedCardHolderName] = useState("");
  const [selectedId, setSelectedId] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [createLoading, setCreateLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isActivate, setIsActivate] = useState(false);

  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [cardHolderName, setCardHolderName] = useState("");
  const [postalCode, setPostalCode] = useState("");

  const [agreeToTerms, setAgreeToTerms] = useState(false);

  const [shippingType, setShippingType] = useState("Standard");

  const [country, setCountry] = useState("selectt");
  const [displayCountry, setDisplayCountry] = useState({
    value: "US",
    label: "United States",
  });

  const [state, setState] = useState(states[0]?.value);

  const setValueHandler = (valueToSet, valueSetter) => {
    valueSetter(valueToSet);
  };

  const history = useHistory();

  useEffect(async () => {
    const { data } = await getCardHolders(searchString);
    if (data) {
      const cardHolders = data.cardholders;
      setCardHolderArray(data.cardholders);
      setSelectedCardHolderName(cardHolders[0]?.name);
      setCardHolderName(cardHolders[0]?.name);
      setSelectedId(cardHolders[0]?.userid);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    if (country === "select") {
      setCity("");
      setState(states[0]?.value);
      setPostalCode("");
      setAddress1("");
    } else {
      setCity("");
      setState(states[0]?.value);
      setPostalCode("");
      setAddress1("");
    }
  }, [country]);
  const nameClickHandler = (name, id) => {
    setErrorMessage("");
    setVisible(false);
    setSelectedCardHolderName(name);
    setCardHolderName(name);
    setSelectedId(id);
  };

  const createCardClickHandler = async () => {
    const connectStatus = getConnectStatus()?.status;
    if (connectStatus !== 1) {
      toast.error("Your stripe account should be verified before ordering card", toastConfiguration);
      return;
    }
    if (!selectedId) {
      setErrorMessage("Please select atleast one card holder.");
    } else if (!agreeToTerms) {
      toast.error("Please agree to terms", toastConfiguration);
    } else {
      if (createLoading) {
        return;
      }
      setCreateLoading(true);
      const { data, error } = await createCard(
        isVirtual
          ? {
            userId: selectedId,
            type: "virtual",
          }
          : {
            userId: selectedId,
            type: "physical",
            recipientName: cardHolderName,
            addressLine1: address1,
            addressLine2: address2,
            service: shippingType?.toLowerCase(),
            addressCity: city,
            addressState: state,
            addressCountry: country,
            addressPostalCode: postalCode,
          }
      );

      if (data) {
        if (isVirtual) {
          trackIntercomEvent("virtual-card-created");
        } else {
          trackIntercomEvent("physical-card-created");
        }
        // if (isActivate) {
        //   const res = await cardStatusChange({
        //     id: data?.card?.id,
        //     status: "active"
        //   })

        //   if (res?.data) {
        //     history.push(`/accountsandcards/cards/${res?.data?.card?.id}`)
        //   }
        // } else {
        history.push(`/cards`);
        //}
      } else if (error) {
        toast.error(error, toastConfiguration);
      }
      setCreateLoading(false);
    }
  };

  useEffect(() => {
    if (!address1) {
      setopen(false);
    } else {
      setopen(true);
    }
  }, [address1]);

  const resetStates = () => {
    setState(states[0]?.value);
    setAddress1("");
    setCity("");
    setPostalCode("");
    const divToClear = document.getElementsByClassName(
      " css-1uccc91-singleValue"
    )[0];
    if (divToClear) {
      divToClear.innerHTML = "";
    }
  };

  const handleAddressChange = async (value, name) => {
    let address = value.label;
    FetchAddress(address, name, setCity, setCountry, setPostalCode, setState);
  };

  return !showCardDetails ? (
    <div>
      <ToastContainer />
      <Card
        className={cn(styles.card, className)}
        title='Add new card'
        border
        head={
          <div style={{ display: "flex", flexDirection: "row" }}>
            <button
              onClick={() => history.push("/cards")}
              className="button-stroke"
            >
              <span>Cancel</span>
            </button>
            <button
              onClick={createCardClickHandler}
              className="button"
              style={{ width: 150, marginLeft: 10 }}
            >
              {createLoading ? (
                <Spinner size='24' color='white' />
              ) : (
                <>
                  Order Card
                </>
              )}
            </button>
          </div>
        }
      >
        <div className={styles.dataMainContainer}>
          <div className={styles.dataContainer}>
            <div className={styles.cardOwnerWrap} style={{ paddingBottom: 20 }}>
              {/* <div className={styles.physicalCardTxt}>
            {!isVirtual && isPhysical ?
              "Physical card costs 5 USD to order and if you proceed, this charge will be applied to your balance. Your card will be sent to the adress we have as your Shipping adress in settings." : null}
          </div> */}
              <div className={styles.title}>Card Holder</div>
              {loading ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Spinner size='24' color={"gray"} />
                </div>
              ) : (
                <Dropdown
                  className={styles.dropdown}
                  nameClickHandler={nameClickHandler}
                  classDropdownHead={styles.dropdownHead}
                  value={selectedCardHolderName}
                  setValue={setSelectedCardHolderName}
                  options={cardHoldersArray}
                  small
                />
              )}
              {/* <Search
            className={styles.search}
            inputPlaceholder="Search card holder"
            searchNames={cardHoldersArray}
            selectedCustomerName={selectedCardHolderName}
            getCustomers={getCardHolders}
            setSearchString={setSearchString}
            setErrorMessage={setErrorMessage}
            visible={visible}
            setVisible={setVisible}
            searchString={searchString}
            nameClickHandler={nameClickHandler}
          /> */}
              {/* {cardOwners.map((item, index) => {
            return <div key={index}><CardOwners item={item} setCardOwners={setCardOwners} cardOwners={cardOwners} /></div>
          })} */}

              <div className={styles.errorMessage}>{errorMessage}</div>
            </div>
            {/* <p className={styles.txt}>
          Choose what type of card you wish to order. You have as many cards as
          you would like. Virtual are free and Physical costs 5 USD/card.
        </p> */}
            <div className={styles.title}>Type</div>
            <div className={styles.cardType}>
              <div className={styles.singleCard}>
                <Checkbox
                  onChange={() => {
                    setIsVirtual(!isVirtual);
                    setIsPhysical(!isPhysical);
                  }}
                  value={isVirtual} //virtual
                />
                <span>
                  Virtual <br />{" "}
                  <div className={styles.cardDetails}>
                    You can use virtual cards instantly
                  </div>
                </span>
              </div>
              <div className={styles.singleCard}>
                <Checkbox
                  onChange={() => {
                    setIsVirtual(!isVirtual);
                    setIsPhysical(!isPhysical);
                  }}
                  value={isPhysical}
                />
                <span>
                  Physical &nbsp;
                  {/* <span className={styles.comingSoon}>
                    (Coming soon)
                  </span> */}
                  <br />
                  <div className={styles.cardDetails}>
                    We ship physical cards to you
                  </div>
                  <div className={styles.cardDetails}>
                    A $10 one time fee will be added to your billing per
                    physical card ordered
                  </div>
                </span>
              </div>

              {/* <div className={styles.singleCard} >
                <Checkbox
                  onChange={() => {
                    setIsActivate(!isActivate);
                  }}
                  value={isActivate}
                />
                <span>Activate Card <br /> <div className={styles.cardDetails}>Allows the cardholder to begin using the card. You can also active the card on card's page</div></span>
              </div> */}
            </div>
          </div>

          {/* <div>
            {isPhysical && (
              <div className={styles.physicalDataContainer}>
                <div className={styles.billingWrapper}>
                  <div className={styles.title}>Recipient Name</div>
                  <div
                    className={styles.row}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      marginTop: 10,
                    }}
                  >
                    <TextInput
                      name='description'
                      value={cardHolderName}
                      placeholder='Card Holder Name'
                      onChange={(event) =>
                        setValueHandler(event?.target?.value, setCardHolderName)
                      }
                      type='text'
                      required
                    />
                  </div>
                  <div className={styles.title}>Shipping Service</div>
                  <div className={styles.row}>
                    <SimpleDropdown
                      className={cn(styles.dropdown)}
                      classDropdownHead={styles.dropdownHead}
                      value={shippingType}
                      setValue={setShippingType}
                      options={["Standard", "Express", "Priority"]}
                    />
                  </div>
                </div>
              </div>
            )}
          </div> */}
        </div>

        {isPhysical && (
          <div style={{ width: "100%" }}>
            <div style={{ width: "298px", marginTop: 30 }}>
              {" "}
              <div
                style={{
                  marginBottom: "14px",
                  fontSize: "14px",
                  fontWeight: "600",
                  lineHeight: 1.71429,
                  // color: "#C7C5BF",
                }}
              >
                Shipping Service
              </div>
              <div className={styles.row}>
                <SimpleDropdown
                  style={{ zIndex: 1 }}
                  className={cn(styles.dropdown)}
                  classDropdownHead={styles.dropdownHead}
                  value={shippingType}
                  setValue={setShippingType}
                  options={["Standard", "Express", "Priority"]}
                />
              </div>
            </div>

            <div
              className={styles.row}
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: 30,
                flexWrap: "wrap",
                zIndex: 2,
              }}
            >
              <div
                style={{
                  marginLeft: 0,
                  width: "298px",
                  zIndex: 2,
                }}
                className={styles.mobileRes}
              >
                <div
                  style={{
                    marginBottom: "14px",
                    fontSize: "14px",
                    fontWeight: "600",
                    lineHeight: 1.71429,
                    color: "#C7C5BF",
                  }}
                >
                  Country
                </div>
                <Select
                  style={{ zIndex: 2 }}
                  options={options}
                  //menuPlacement="auto"
                  className={cn(styles.dropdown, "drop")}
                  // value={displayCountry}
                  onChange={(item) => {
                    item?.value === "US" || item?.value === "select"
                      ? setState(states[0]?.value)
                      : setState("");
                    setCountry(item?.value);
                    setDisplayCountry(item);
                    resetStates();
                  }}
                />
              </div>
            </div>

            <div
              // className={styles.row}
              style={{
                display: "flex",
                flexDirection: "row",
                position: "relative",
                marginTop: 10,
              }}
            >
              <div>
                <div
                  style={{
                    marginBottom: "14px",
                    fontSize: "14px",
                    fontWeight: "600",
                    lineHeight: 1.71429,
                    color: "#C7C5BF",
                  }}
                >
                  Address
                </div>
                <GooglePlacesAutocomplete
                  autocompletionRequest={{
                    componentRestrictions: { country: [country] },
                    types: ["geocode"],
                  }}
                  selectProps={{
                    placeholder: "Enter Address",
                    styles: {
                      input: (provided) => ({
                        ...provided,
                        width: "240px",
                        height: "40px",
                      }),
                      menu: (provided) => ({
                        ...provided,
                        zIndex: 100,
                      }),
                    },

                    address1,
                    onKeyDown: (event) => {
                      var keyId = event.keyCode;
                      if (keyId === 8) {
                      }
                    },
                    onChange: (e) => {
                      setAddress1(e.label);
                      handleAddressChange(e, "cardaddress");
                    },
                  }}
                ></GooglePlacesAutocomplete>
                <button
                  className={styles.close}
                  onClick={() => {
                    resetStates();
                  }}
                >
                  <Icon name='close-circle' size='24' />
                </button>

                {/* <TextInput
                      name="description"
                      value={address1}
                      placeholder="Address Line 1"
                      onChange={(event) => setValueHandler(event?.target?.value, setAddress1)}
                      type="text"
                      required
                    /> */}
              </div>
              {open && (
                <div
                  style={{ width: "298px", marginLeft: "50px" }}
                  className={styles.mobileRes}
                >
                  <TextInput
                    name='description'
                    label='City'
                    placeholder='City'
                    value={city}
                    onChange={(event) =>
                      setValueHandler(event?.target?.value, setCity)
                    }
                    type='text'
                    required
                  />
                </div>
              )}
            </div>

            {open && (
              <div
                className={styles.row}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: 13,
                  flexWrap: "wrap",
                }}
              >
                <div style={{ zIndex: 1, width: "298px" }}>
                  {country === "US" || country === "select" ? (
                    <DropdownState
                      className={styles.dropdown}
                      classDropdownHead={styles.dropdownHead}
                      value={state}
                      label='State'
                      setValue={setState}
                      options={country === "select" ? [states[0]] : states}
                    />
                  ) : (
                    <TextInput
                      name='state'
                      placeholder='State'
                      value={state}
                      label='State'
                      onChange={(event) =>
                        setValueHandler(event?.target?.value, setState)
                      }
                      type='text'
                      required
                    />
                  )}
                </div>
                {/* <div>
                      <TextInput
                        name='description'
                        value={address2}
                        placeholder='Address Line 2'
                        onChange={(event) =>
                          setValueHandler(event?.target?.value, setAddress2)
                        }
                        type='text'
                        required
                      />
                    </div> */}
                <div
                  style={{ width: "298px", marginLeft: "50px" }}
                  className={styles.mobileRes}
                >
                  <TextInput
                    name='postalCode'
                    value={postalCode}
                    label='Postal Code'
                    placeholder='Postal Code'
                    onChange={(event) =>
                      setValueHandler(event?.target?.value, setPostalCode)
                    }
                    type='text'
                    required
                  />
                </div>
              </div>
            )}
          </div>
        )}
        <div style={{ marginTop: 24 }}>
          <Checkbox
            content={"By continuing, I agree to the Authorised User Terms"}
            value={agreeToTerms}
            onChange={() => {
              setAgreeToTerms(!agreeToTerms);
            }}
          />
        </div>
      </Card>
    </div>
  ) : (
    <CardDetails
      setShowCards={setShowCards}
      setShowCardDetails={setShowCardDetails}
      setShowNewCard={setShowNewCard}
      setAddCard={setAddCard}
    />
  );
};

export default NewCard;
