import React, { useState, useEffect } from "react";
import styles from "./SubscriptionsCustomer.module.sass";
import DetailsCustomer from "./DetailsCustomer";
import Search from "../Search";
import AccountInfo from "../../../Invoices/NewInvoice/Modals/AccountInfo";
import {
  addQuote,
  searchCustomer,
  searchProduct,
  searchProductPaymentLink,
  updateQuote,
} from "../../../../../utils/apiCallHanlder";
import cn from "classnames";
import Spinner from "../../../../../utils/spinner";
import Card from "../../../../../components/Card";
import { toastConfiguration } from "../../../../../utils/utils";
import { ToastContainer, toast } from "react-toastify";
import { useHistory } from "react-router";

const searchNames = ["Customer #1", "Customer #2"];

const SubscriptionsCustomer = ({
  className,
  setShowActionBtn,
  setShowSuccessModal,
  dbTaxRates,
  setShowSummary,
  selectedCustomerObject,
  setSelectedCustomerObject,
  lines,
  setLines,
  startDate,
  paymentMethodsToSend,
  endDate,
  setStartDate,
  setEndDate,
  durations,
  setDurations,
  activeCardPaymentType,
  activeAchPaymentType,
  setActiveAchPaymentType,
  paymentMethods,
  setActiveCardPaymentType,
  memo,
  setMemo,
  intervals,
  showDetails,
  setShowDetails,
  customerTaxExempt,
  showProduct,
  setShowProduct,
  subscription,
  setSubscription,
  lineId,
  setLineId,
  id,
  schedulePaymentClickHandler,
  addSubscriptionLoading,
  todayDate,
  setTodayDate,
  dateSelector,
  setDateSelector,
  foreverDate,
  setForeverDate,
  endDateSelector,
  setEndDateSelector,
  showNewProductModal,
  setShowNewProductModal
}) => {
  const [showAccountInfo, setShowAccountInfo] = useState(false);

  const [visible, setVisible] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [searchNames, setSearchNames] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);

  const [addQuoteLoading, setAddQuoteLoading] = useState(false);

  const [products, setProducts] = useState([]);

  const history = useHistory();

  useEffect(async () => {
    if (visible) {
      setSearchLoading(true);
      await getCustomers(searchString);
      setSearchLoading(false);
    }
  }, [visible]);

  const getCustomers = async (searchString) => {
    const { data } = await searchCustomer(searchString);
    if (data) {
      setSearchNames(data?.list);
    }
  };

  const getProducts = async (searchString) => {
    const { data } = await searchProductPaymentLink(searchString, "one_time");
    if (data) {
      setProducts(data?.productList);
    }
  };

  const nameClickHandler = async (name, id, imageUrl, email, phone) => {
    setAddQuoteLoading(true);
    if (id !== selectedCustomerObject.id) {
      // if (selectedCustomerObject.id) {
      //   const { data } = await updateQuote({
      //     id: subscription.id,
      //     customerId: id
      //   })
      //   if (data) {
      //     history.push(`/quote/${data?.quote?.id}`)
      //   } else {
      //     toast.error("Error in updating quote", toastConfiguration);
      //   }
      // } else {
      const { data } = await addQuote({
        customerId: id,
      });
      if (data) {
        history.push(`/quote/${data?.quote?.id}`);
      } else {
        toast.error("Error in adding quote", toastConfiguration);
        //}
      }
    }
    setAddQuoteLoading(false);
    setVisible(false);
  };

  return (
    <>
      <ToastContainer />
      {!showDetails ? (
        <Card
          className={cn(styles.card, className)}
          title="Quote Details"
          border
          /* classTitle="title-blue" */
        >
          <>
            <Search
              className={styles.search}
              inputPlaceholder="Search or add"
              addNewBtnTxt="Create new Customer"
              addNewItem={() => setShowAccountInfo(true)}
              searchNames={searchNames}
              setSearchNames={setSearchNames}
              selectedCustomerName={selectedCustomerObject?.name}
              getCustomers={getCustomers}
              addQuoteLoading={addQuoteLoading}
              setSearchString={setSearchString}
              visible={visible}
              setShowDetails={setShowDetails}
              setVisible={setVisible}
              searchString={searchString}
              searchLoading={searchLoading}
              setSearchLoading={setSearchLoading}
              nameClickHandler={nameClickHandler}
            />
            <AccountInfo
              showAccountInfo={showAccountInfo}
              setShowAccountInfo={setShowAccountInfo}
              setShowDetails={setShowDetails}
              searchString={searchString}
              nameClickHandler={nameClickHandler}
            />
          </>
        </Card>
      ) : (
        <DetailsCustomer
          setShowDetails={() => setShowDetails(false)}
          setShowAccountInfo={() => setShowAccountInfo(true)}
          setShowActionBtn={setShowActionBtn}
          setShowSummary={setShowSummary}
          selectedCustomerObject={selectedCustomerObject}
          getProducts={getProducts}
          products={products}
          customerTaxExempt={customerTaxExempt}
          lines={lines}
          dbTaxRates={dbTaxRates}
          setLines={setLines}
          startDate={startDate}
          paymentMethods={paymentMethods}
          endDate={endDate}
          setStartDate={setStartDate}
          subscription={subscription}
          setSubscription={setSubscription}
          setEndDate={setEndDate}
          paymentMethodsToSend={paymentMethodsToSend}
          durations={durations}
          setDurations={setDurations}
          activeCardPaymentType={activeCardPaymentType}
          activeAchPaymentType={activeAchPaymentType}
          setActiveAchPaymentType={setActiveAchPaymentType}
          setActiveCardPaymentType={setActiveCardPaymentType}
          memo={memo}
          intervals={intervals}
          setMemo={setMemo}
          showProduct={showProduct}
          setShowProduct={setShowProduct}
          lineId={lineId}
          setLineId={setLineId}
          id={id}
          schedulePaymentClickHandler={schedulePaymentClickHandler}
          addSubscriptionLoading={addSubscriptionLoading}
          todayDate={todayDate}
          setTodayDate={setTodayDate}
          dateSelector={dateSelector}
          setDateSelector={setDateSelector}
          foreverDate={foreverDate}
          setForeverDate={setForeverDate}
          endDateSelector={endDateSelector}
          setEndDateSelector={setEndDateSelector}
          setShowSuccessModal={setShowSuccessModal}
          showNewProductModal={showNewProductModal}
          setShowNewProductModal={setShowNewProductModal}
        />
      )}
    </>
  );
};

export default SubscriptionsCustomer;
