import Styles from './Pipeline.module.sass'
import Card from '../../../components/Card';
import { useHistory } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import Board from '@asseinfo/react-kanban'
import '@asseinfo/react-kanban/dist/styles.css'
import Icon from '../../../components/Icon';
import Search from '../../MessagesWithAI/prompts/Search';
import { useEffect, useState } from 'react';
import TeamsDropdown from '../../../components/Dropdown_c';
import Spinner from '../../../utils/spinner';
import { getAllTasks, getToDOSpaces, updateToDoAcrossLaneOrder, updateToDoTasksOrder } from '../../../utils/apiCallHanlder';
import { ordinalDateformatter, truncateWithEllipsis } from '../../../utils/utils';

let boardData = { columns: [] }

const CardComp = (data) => {
    return <div className={Styles.cardContainer}
        onClick={data?.onClick}
        onChange={data?.onChange}
    >
        <span className={Styles.cardTitle}>{truncateWithEllipsis(data?.taskNumber + ' - ' + data?.title, 28)}</span>
        <span className={Styles.cardDescription}>{truncateWithEllipsis(data?.description, 60)}</span>
        <div className={Styles.extraInfo}>
            <Icon name="user" size="24" fill="#464542" />
            <span className={Styles.margin}>{data?.assignedToName}</span>
        </div>
        <div className={Styles.extraInfo} style={{ marginTop: 4 }}>
            <Icon name="calendar" size="24" fill="#464542" />
            <span className={Styles.margin}>{ordinalDateformatter(new Date(data?.dueOn))}</span>
        </div>
    </div >
}

const LaneHeader = (data) => {
    return (
        <div className={Styles.headerRow}>
            <div className={Styles.headerTitle}>{data?.title}</div>
        </div>
    )
}

const Pipeline = ({ }) => {
    const history = useHistory();

    const [loading, setLoading] = useState(true);
    const [spaces, setSpaces] = useState([]);

    const [filter, setFilter] = useState("All");
    const [searchStr, setSearchStr] = useState('');

    useEffect(() => {
        getPipelineCardsAndColumns();
        getSpaces();
    }, [])

    const getSpaces = async () => {
        const { data } = await getToDOSpaces();
        if (data?.list) {
            const newPopulatedValues = [{
                name: 'All',
                id: 'All'
            }, ...data?.list?.map(item => {
                return {
                    name: item?.title,
                    id: item?.toDoSpaceId
                }
            })]
            setSpaces(newPopulatedValues || []);
        }
    }

    const getPipelineCardsAndColumns = async (search = '', toDoSpaceId, hideLoading) => {
        if (!search) setSearchStr('');
        if (!toDoSpaceId) setFilter('All');

        !hideLoading && setLoading(true);
        boardData = { columns: [] }
        const { data } = await getAllTasks({
            searchString: search,
            toDoSpaceId: (!toDoSpaceId || toDoSpaceId === 'All') ? null : toDoSpaceId
        });

        if (data) {
            const calculatedData = [];

            data?.columns?.map(column => {
                const columnCards = [];
                data?.list?.map(list => {
                    if (list?.toDoPipelineId === column?.toDoPipelineId) {
                        columnCards.push({
                            id: list?.toDoTaskId,
                            taskNumber: list?.taskNumber,
                            title: list?.title,
                            description: list?.detail || '-',
                            assignedToName: list?.assignedToName,
                            dueOn: list?.dueOn,
                            hideCardDeleteIcon: true,
                            onClick: () => cardClickHandler(list?.toDoTaskId, column?.toDoPipelineId)
                        });
                    }
                })
                calculatedData.push({
                    id: column?.toDoPipelineId,
                    title: column?.title,
                    name: column?.title,
                    cards: [...columnCards]
                })
            })

            boardData = { columns: calculatedData };
        }

        !hideLoading && setLoading(false);
    }

    const handleDragEnd = (board, cardDetails, source, destination) => {
        const sourceLaneId = source?.fromColumnId;
        const targetLaneId = destination?.toColumnId;
        const position = destination?.toPosition;
        const cardId = cardDetails?.id;

        if (sourceLaneId === targetLaneId) {
            handleSameLineMovement(sourceLaneId, cardId, position);
        } else {
            handleAcrossLineMovement(cardId, targetLaneId, cardDetails, position, sourceLaneId);
        }
    }

    const handleSameLineMovement = async (laneId, cardId, currentPosition) => {
        boardData?.columns?.map?.(async (lane) => {
            if (lane?.id === laneId) {
                const card = lane?.cards?.filter(item => item?.id === cardId)
                const laneCardsWithoutTargetCard = lane?.cards?.filter(item => item?.id !== cardId)
                laneCardsWithoutTargetCard?.splice(currentPosition, 0, card[0]);
                lane.cards = laneCardsWithoutTargetCard;

                const laneOrder = laneCardsWithoutTargetCard?.map(item => item?.id);
                await updateToDoTasksOrder({ ids: laneOrder });
            }
        })
    }

    const handleAcrossLineMovement = async (cardId, targetLaneId, cardDetails, position, sourceLaneId) => {
        boardData?.columns?.map((lane) => {
            if (lane?.id === sourceLaneId) {
                lane?.cards?.filter(item => item?.id !== cardId);
            } else if (lane?.id === targetLaneId) {
                lane?.cards?.splice(position, 0, cardDetails);
            }
        })

        await updateToDoAcrossLaneOrder({
            toDoTaskId: cardId,
            ToDoPipelineId: targetLaneId,
            newOrder: position
        })

        await getPipelineCardsAndColumns(null, null, true);
    }

    const cardClickHandler = async (taskId, columnId) => {
        history.push(`/board/${taskId}`)
    }

    return (
        <>
            <ToastContainer />
            <>
                <div className={Styles.filterContainer}>
                    <Search
                        setSearchString={setSearchStr}
                        searchString={searchStr}
                        getSearchResult={() => {
                            getPipelineCardsAndColumns(searchStr, filter);
                        }}
                        resetSearch={() => {
                            setSearchStr('');
                            getPipelineCardsAndColumns();
                        }}
                    />
                    <TeamsDropdown
                        onChange={(value) => {
                            getPipelineCardsAndColumns(searchStr, value);
                        }}
                        className={Styles.dropdownFilter}
                        classDropdownHead={Styles.dropdownHead}
                        value={filter}
                        setValue={setFilter}
                        bodyStyles={{ width: '100%' }}
                        options={spaces}
                    />
                </div>

                {loading && (
                    <div className={Styles.spinnerContainer}>
                        <Spinner size="48" color="gray" />
                    </div>
                )}
                {boardData?.columns?.length > 0 && !loading && (
                    <Card className={Styles.pipelineCard}>
                        <Board
                            initialBoard={boardData}
                            onCardDragEnd={handleDragEnd}
                            style={{ backgroundColor: 'transparent', height: 'max-content' }}
                            laneStyle={{ backgroundColor: 'transparent' }}
                            renderCard={CardComp}
                            renderColumnHeader={LaneHeader}
                            disableColumnDrag={true}
                        />
                    </Card>
                )}
            </>
        </>
    )
}

export default Pipeline;