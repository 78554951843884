// [START solar_api_data_types]

// Note: Removed interfaces as they are TypeScript-specific constructs. 

// [END solar_api_data_types]

// https://developers.google.com/maps/documentation/solar/reference/rest/v1/dataLayers
// Note: No need to convert type alias in JavaScript.

// [START solar_api_building_insights]
/**
 * Fetches the building insights information from the Solar API.
 *   https://developers.google.com/maps/documentation/solar/building-insights
 *
 * @param  {object} location      Point of interest as latitude longitude.
 * @param  {string} apiKey        Google Cloud API key.
 * @return {Promise<object>}      Building Insights response.
 */

import * as geotiff from 'geotiff';
import * as geokeysToProj4 from 'geotiff-geokeys-to-proj4';
import proj4 from 'proj4';


export async function findClosestBuilding(
    location,
    apiKey,
  ) {
    const args = {
      'location.latitude': location.lat().toFixed(5),
      'location.longitude': location.lng().toFixed(5),
    };
    console.log('GET buildingInsights\n', args);
    const params = new URLSearchParams({ ...args, key: apiKey });
    // https://developers.google.com/maps/documentation/solar/reference/rest/v1/buildingInsights/findClosest
    return fetch(`https://solar.googleapis.com/v1/buildingInsights:findClosest?${params}`).then(
      async (response) => {
        const content = await response.json();
        if (response.status != 200) {
          console.error('findClosestBuilding\n', content);
          throw content;
        }
        console.log('buildingInsightsResponse', content);
        return content;
      },
    );
  }
  // [END solar_api_building_insights]
  
  // [START solar_api_data_layers]
  /**
   * Fetches the data layers information from the Solar API.
   *   https://developers.google.com/maps/documentation/solar/data-layers
   *
   * @param  {object} location      Point of interest as latitude longitude.
   * @param  {number} radiusMeters  Radius of the data layer size in meters.
   * @param  {string} apiKey        Google Cloud API key.
   * @return {Promise<object>}      Data Layers response.
   */
  export async function getDataLayerUrls(
    location,
    radiusMeters,
    apiKey,
  ) {
    const args = {
      'location.latitude': location.latitude.toFixed(5),
      'location.longitude': location.longitude.toFixed(5),
      radius_meters: radiusMeters.toString(),
      required_quality: 'LOW',
    };
    console.log('GET dataLayers\n', args);
    const params = new URLSearchParams({ ...args, key: apiKey });
    // https://developers.google.com/maps/documentation/solar/reference/rest/v1/dataLayers/get
    return fetch(`https://solar.googleapis.com/v1/dataLayers:get?${params}`).then(
      async (response) => {
        const content = await response.json();
        if (response.status != 200) {
          console.error('getDataLayerUrls\n', content);
          throw content;
        }
        console.log('dataLayersResponse', content);
        return content;
      },
    );
  }
  // [END solar_api_data_layers]
  
  // [START solar_api_download_geotiff]
  // npm install geotiff geotiff-geokeys-to-proj4 proj4
  

  /**
   * Downloads the pixel values for a Data Layer URL from the Solar API.
   *
   * @param  {string} url        URL from the Data Layers response.
   * @param  {string} apiKey     Google Cloud API key.
   * @return {Promise<object>}   Pixel values with shape and lat/lon bounds.
   */
  export async function downloadGeoTIFF(url, apiKey) {
    console.log(`Downloading data layer: ${url}`);
  
    // Include your Google Cloud API key in the Data Layers URL.
    const solarUrl = url.includes('solar.googleapis.com') ? url + `&key=${apiKey}` : url;
    const response = await fetch(solarUrl);
    if (response.status != 200) {
      const error = await response.json();
      console.error(`downloadGeoTIFF failed: ${url}\n`, error);
      throw error;
    }
  
    // Get the GeoTIFF rasters, which are the pixel values for each band.
    const arrayBuffer = await response.arrayBuffer();
    const tiff = await geotiff.fromArrayBuffer(arrayBuffer);
    const image = await tiff.getImage();
    const rasters = await image.readRasters();
  
    // Reproject the bounding box into lat/lon coordinates.
    const geoKeys = image.getGeoKeys();
    const projObj = geokeysToProj4.toProj4(geoKeys);
    const projection = proj4(projObj.proj4, 'WGS84');
    const box = image.getBoundingBox();
    const sw = projection.forward({
      x: box[0] * projObj.coordinatesConversionParameters.x,
      y: box[1] * projObj.coordinatesConversionParameters.y,
    });
    const ne = projection.forward({
      x: box[2] * projObj.coordinatesConversionParameters.x,
      y: box[3] * projObj.coordinatesConversionParameters.y,
    });
  
    return {
      // Width and height of the data layer image in pixels.
      width: rasters.width,
      height: rasters.height,
      // Each raster represents the pixel values of each band.
      rasters: [...Array(rasters.length).keys()].map((i) =>
        Array.from(rasters[i]),
      ),
      // The bounding box as a lat/lon rectangle.
      bounds: {
        north: ne.y,
        south: sw.y,
        east: ne.x,
        west: sw.x,
      },
    };
  }
  // [END solar_api_download_geotiff]
  
  export function showLatLng(point) {
    return `(${point.latitude.toFixed(5)}, ${point.longitude.toFixed(5)})`;
  }
  
  export function showDate(date) {
    return `${date.month}/${date.day}/${date.year}`;
  }
  