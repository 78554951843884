import React, { useEffect, useRef, useState } from 'react';
import TextInput from '../../../components/TextInput';
import GooglePlacesAutocomplete, { geocodeByPlaceId, geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';

const AddressSearch = ({ location, setLocation, placesLibrary, map, initialValue = '', zoom = 19 }) => {
  const textFieldRef = useRef(null);

  const [address, setAddress] = useState(initialValue);

 // useEffect(() => {
 //   const textFieldElement = textFieldRef.current;
    

//     debugger;
//     const autocomplete = new placesLibrary.Autocomplete(textFieldElement.querySelector('input'), {
//       fields: ['formatted_address', 'geometry', 'name'],
//     });

//     autocomplete.addListener('place_changed', async () => {
//         debugger;
//       const place = autocomplete.getPlace();
//       if (!place.geometry || !place.geometry.location) {
//         textFieldElement.value = '';
//         return;
//       }
//       if (place.geometry.viewport) {
//         map.setCenter(place.geometry.location);
//         map.setZoom(zoom);
//       } else {
//         map.setCenter(place.geometry.location);
//         map.setZoom(zoom);
//       }

//       location.current = place.geometry.location;
//       textFieldElement.value = place.name || place.formatted_address || '';
//     });
//   }, [placesLibrary, map, zoom]);

  return (
    // <TextInput ref={textFieldRef} label="Search an address" defaultValue={initialValue}>

    // </TextInput>
    // <md-filled-text-field ref={textFieldRef} label="Search an address" defaultValue={initialValue}>
    //   {/* <md-icon slot="leadingicon">search</md-icon> */}
    // </md-filled-text-field>
    

    // <input type='text' ref={textFieldRef} label="Search an address" defaultValue={initialValue}>
    
    //  </input>

        <GooglePlacesAutocomplete
            autocompletionRequest={{
            types: ["geocode"],
            
        }}
        selectProps={{
            //placeholder: address ? address : "",
            formatOptionLabel: ({ label, description }) => (
                <div>
                    <span>{label}</span>
                    <span>{description}</span>
                </div>
            ),
            styles: {
                input: (provided) => ({
                    ...provided,
                    width: "33%",
                    height: "40px"
                }),
                control: (provided) => ({
                    ...provided,
                    backgroundColor: '#F4F4F4',
                    border: '2px solid #C7C5BF',
                    color: '#46455A',
                    borderRadius: '0px',
                    height: '48px',
                })
            },

            address,
            onChange: (e) => {
                setAddress(e.label);
                geocodeByPlaceId(e.value.place_id)
                    .then(results => {
                        console.log(results);

                        map.setCenter(results[0].geometry.location);
                        map.setZoom(zoom);
                        setLocation(results[0].geometry.location);
                        //location.current = results[0].geometry.location;
                        setAddress(results[0].formatted_address);
                    })
                    .catch(error => console.error(error));
                
                //setMapLocation(e.label);
            },
            defaultInputValue: address,
        }}
/>

  );


};

export default AddressSearch;