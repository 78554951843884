import React, { useState, useEffect } from "react";
import styles from "./InvoiceCustomer.module.sass";
import DetailsCustomer from "./DetailsCustomer";
import Search from "../Search";
import AccountInfo from "../Modals/AccountInfo";
import cn from "classnames";
import Card from "../../../../../components/Card";
import {
  searchCustomer,
  addInvoice,
  changeInvoiceCustomer,
  searchProduct,
} from "../../../../../utils/apiCallHanlder";
import Spinner from "../../../../../utils/spinner";
import { useHistory } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import { toastConfiguration } from "../../../../../utils/utils";

const InvoiceCustomer = ({
  className,
  setShowActionBtn,
  setInvoiceObject,
  setUpdateInvoice,
  invoiceId,
  invoiceObject,
  startDataLoading,
  selectedCustomerName,
  customerImage,
  updateInvoice,
  showDetail,
  invoiceStatus,
  setStartDataLoading,
  setSelectedCustomerName,
  setShowDetail,
  isManual,
  isThroughStripe,
  setIsManual,
  setIsThroughStripe,
  sendButton,
  setCustomerImage,
  selectedCustomerPhone,
  selectedCustomerEmail,
  customerTaxExempt,
  dbTaxRates,
  connect,
  downloadButton,
  showNewProductModal,
  setShowNewProductModal
}) => {
  const [showAccountInfo, setShowAccountInfo] = useState(false);
  const [searchNames, setSearchNames] = useState([]);
  const [searchNamesProducts, setSearchNamesProducts] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [selectCustomerLoading, setSelectCustomerLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const history = useHistory();

  useEffect(async () => {
    if (
      Object.keys(invoiceObject).length === 0 &&
      invoiceObject?.status === "draft"
    ) {
      setStartDataLoading(true);
      await getCustomers("");
      if (invoiceId) {
        await getProducts("");
      }
    }
    setStartDataLoading(false);
  }, [invoiceId, invoiceObject]);

  const getCustomers = async (searchString) => {
    const { data } = await searchCustomer(searchString);
    if (data) {
      setSearchNames(data?.list);
    }
  };

  const getProducts = async (searchString) => {
    const { data } = await searchProduct(searchString, "one_time");
    if (data) {
      setSearchNamesProducts(data?.productList);
    }
  };

  const showDetails = () => {
    setShowDetail(true);
  };

  const nameClickHandler = async (name, id, imageUrl, email) => {
    let setCustomerDetails = true;

    if (name === selectedCustomerName) {
      showDetails();
    } else if (selectedCustomerName === "") {
      setSelectCustomerLoading(true);
      const { data, error } = await addInvoice({
        customerId: id,
        customerImage: imageUrl,
      });
      if (data) {
        history.push(`/payments/${data?.invoice?.id}`);
        setUpdateInvoice(!updateInvoice);
      } else {
        setCustomerDetails = false;
        toast && toast.error(error, toastConfiguration);
      }
      setSelectCustomerLoading(false);
    } else {
      setSelectCustomerLoading(true);
      const res = await changeInvoiceCustomer({
        customerId: id,
        invoiceId: invoiceId,
        customerImage: imageUrl,
      });
      const { data, error } = res;
      if (data) {
        history.push(`/payments/${data?.invoice?.id}`);
        setUpdateInvoice(!updateInvoice);
      } else {
        setCustomerDetails = false;
        toast && toast.error(error, toastConfiguration);
      }
      setSelectCustomerLoading(false);
    }

    if (setCustomerDetails) {
      setSelectedCustomerName(name);
      setVisible(false);
      setCustomerImage(imageUrl);
    }
  };

  return (
    <>
      {!showDetail ? (
        <Card
          className={cn(styles.card, className)}
          title='Invoice Details'
          border
        /* classTitle="title-blue" */
        >
          {startDataLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: 70,
              }}
            >
              <Spinner loading={startDataLoading} size={40} color={"gray"} />
            </div>
          ) : (
            <>
              <Search
                className={styles.search}
                inputPlaceholder='Search or add'
                addNewBtnTxt='Add new Customer'
                addNewItem={() => setShowAccountInfo(true)}
                searchNames={searchNames}
                selectedCustomerName={selectedCustomerName}
                setShowDetails={setShowDetail}
                getCustomers={getCustomers}
                setSearchString={setSearchString}
                visible={visible}
                setVisible={setVisible}
                setSearchNames={setSearchNames}
                searchString={searchString}
                selectCustomerLoading={selectCustomerLoading}
                nameClickHandler={nameClickHandler}
              />
              <AccountInfo
                showAccountInfo={showAccountInfo}
                setShowAccountInfo={setShowAccountInfo}
                setShowDetails={setShowDetail}
                searchString={searchString}
                nameClickHandler={nameClickHandler}
              />
            </>
          )}
        </Card>
      ) : (
        <DetailsCustomer
          setShowDetails={() => setShowDetail(false)}
          setShowAccountInfo={() => setShowAccountInfo(true)}
          setShowAccountInfos={setShowAccountInfo}
          setShowActionBtn={setShowActionBtn}
          selectedCustomerName={selectedCustomerName}
          setUpdateInvoice={setUpdateInvoice}
          dbTaxRates={dbTaxRates}
          updateInvoicee={updateInvoice}
          customerTaxExempt={customerTaxExempt}
          customerImage={customerImage}
          searchNamesProducts={searchNamesProducts}
          invoiceId={invoiceId}
          setInvoiceObject={setInvoiceObject}
          connect={connect}
          getProducts={getProducts}
          invoiceObject={invoiceObject}
          isManual={isManual}
          isThroughStripe={isThroughStripe}
          setIsManual={setIsManual}
          setSearchNamesProducts={setSearchNamesProducts}
          setIsThroughStripe={setIsThroughStripe}
          sendButton={sendButton}
          selectedCustomerEmail={selectedCustomerEmail}
          selectedCustomerPhone={selectedCustomerPhone}
          downloadButton={downloadButton}
          showNewProductModal={showNewProductModal}
          setShowNewProductModal={setShowNewProductModal}
        />
      )}
    </>
  );
};

export default InvoiceCustomer;
