import { reverifyAccount, syncBankAccountWithAccounting } from "./apiCallHanlder";
import * as Sentry from "@sentry/react";

const logoutAfterXMinIdle = 45;
let alreadyLaunchedIntercom = false;

export const messageDateTimeFormatter = (date = new Date()) => {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();
  const hour = date.getHours();
  const minute = date.getMinutes();
  const formattedDate = day + ' ' + month + ' ' + year + ', ' + (hour < 10 ? '0' : '') + hour + ':' + (minute < 10 ? '0' : '') + minute;

  return formattedDate;
}

export const getQuarterDates = (value) => {
  let today = new Date(),
    quarter = Math.floor((today.getMonth() / 3)),
    startDate,
    endDate;

  switch (value) {
    case "previous":
      startDate = new Date(today.getFullYear(), quarter * 3 - 3, 1);
      endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 3, 0);
      break;
    default:
      startDate = new Date(today.getFullYear(), quarter * 3, 1);
      endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 3, 0);
      break;
  }

  return {
    StartDate: startDate,
    EndDate: endDate
  };
}

export const SetAlreadyLaunchedIntercom = (x) => {
  alreadyLaunchedIntercom = x
};

export const GetAlreadyLaunchedIntercom = () => {
  return alreadyLaunchedIntercom;
};

export const numberWithCommas = (x) => {
  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
};

export const isValidHttpUrl = (string) => {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
}

export const camelCaseToWords = (s) => {
  const result = s.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
}

export const formatDateCapital = (date = new Date()) => {
  const day = date.getDate();
  const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear();

  const daySuffix = (day) => {
    if (day > 3 && day < 21) return 'th'; // catch 11th, 12th, 13th
    switch (day % 10) {
      case 1: return "st";
      case 2: return "nd";
      case 3: return "rd";
      default: return "th";
    }
  };

  return `${day}${daySuffix(day)} ${month}, ${year}`;
}

export const getAlternativeIconText = (name) => {
  if (!name || !name.trim()) {
    return "";
  }

  if (name.trim().length === 1) {
    return name.trim().toLocaleUpperCase();
  }

  let arrOfName = name.trim().split(" ");
  arrOfName = arrOfName.filter((part) => part.trim().length > 0);

  if (arrOfName.length === 1) {
    return arrOfName[0].slice(0, 2).toLocaleUpperCase();
  }

  return (arrOfName[0][0] + arrOfName[1][0]).toLocaleUpperCase();
};

export const getRandomNumberForColor = (name) => {
  if (!name || !name.trim()) {
    return 0;
  }

  const preparedName = name
    .trim()
    .toLocaleUpperCase()
    .split(" ")
    .filter((item) => item.trim().length > 0)
    .join("")
    .split("");

  const sum = preparedName.reduce((sum, curr) => sum + curr.charCodeAt(0), 0);

  return Math.abs(sum % 9);
};

export const progress = () => {
  return Math.floor(Math.random() * 90) + 10 + "%";
};

export const gettingPermissionByFeature = (feature) => {
  const rights = JSON.parse(localStorage.getItem("role"))?.rights;
  return rights[feature] || 0;
};

export const getAuthToken = () => {
  return localStorage.getItem("token");
};

export const localStorageItem = (itemName, itemToSave) => {
  localStorage.setItem(itemName, itemToSave);
};

export const localStorageGetItem = (itemName) => {
  localStorage.getItem(itemName);
};

export const localStorageRemoveItem = (itemName) => {
  localStorage.removeItem(itemName);
};

export const isUserLoggedIn = () => {
  return !!getAuthToken();
};

export const gmtToUTCTimeConversion = (dateToCheck) => {
  const seconds = new Date(dateToCheck).getUTCSeconds();
  const minutes = new Date(dateToCheck).getUTCMinutes();
  const hours = new Date(dateToCheck).getUTCHours();
  const date = new Date(dateToCheck).getUTCDate();
  const month = new Date(dateToCheck).getUTCMonth();
  const year = new Date(dateToCheck).getUTCFullYear();

  const newDate = new Date();
  newDate?.setSeconds(seconds);
  newDate?.setMinutes(minutes);
  newDate?.setHours(hours);
  newDate?.setDate(date);
  newDate?.setMonth(month);
  newDate?.setFullYear(year);

  return newDate;
};

export const handleLoginSignUpResponse = async (
  { token, role, redirectURL, company, user, isFirstLogin, hasMultiStore, userCompanies, noRedirect, chatApiToken },
  history,
  doSignUp
) => {
  handleLoginSignUpResponseWithoutRedirect({ token, role, company, user, hasMultiStore, userCompanies });

  localStorageItem("hasMultiStore", hasMultiStore);
  localStorageItem("userCompanies", JSON.stringify(userCompanies));
  localStorageItem("chatAPiToken", chatApiToken);



  //let's keep these, to not break old functionality, we will retain login values
  localStorageItem("isAccountingEnabled", company?.isAccountingEnabled);
  localStorageItem("isBookkeepingEnabled", company?.isBookkeepingEnabled);
  localStorageItem("isStudioEnabled", company?.isStudioEnabled);

  let isPropertyEnabled = true;
  if (company?.isPropertyEnabled === false)
    isPropertyEnabled = false;
  localStorageItem("isPropertyEnabled", isPropertyEnabled);

  if (noRedirect) {
    return;
  }

  if (doSignUp) {
    reverifyHandler();
  } else if (redirectURL) {
    window.location = redirectURL;
  } else if (isFirstLogin) {
    history.push("/complete-profile");
  } else {
    history.push("/dashboard");
    //await syncBankAccountWithAccounting({});
  }
};

export const verifyIdleLogout = () => {
  let lastApiCallTimestamp = localStorage.getItem("lastApiCallTimestamp");
  if (lastApiCallTimestamp) {
    let parsedDate = Date.parse(lastApiCallTimestamp);
    let diffInms = new Date() - parsedDate;
    let diffInMin = Math.round(diffInms / 60000);
    if (diffInMin >= logoutAfterXMinIdle) {
      const customHistory = {
        push: (path) => {
          window.location = path;
        },
      };
      logoutHandler(customHistory);
    }
  }
  localStorageItem("lastApiCallTimestamp", new Date());
};

export const handleLoginSignUpResponseWithoutRedirect = ({
  token,
  role,
  company,
  user,
  hasMultiStore,
  userCompanies
}, skipMultiCompany) => {
  const activatedStatus = {
    isAccountingEnabled: company?.isAccountingEnabled,
    isBookkeepingEnabled: company?.isBookkeepingEnabled,
    isCardsEnabled: company?.isCardsEnabled,
    isContactsEnabled: company?.isContactsEnabled,
    isDashboardEnabled: company?.isDashboardEnabled,
    isGetPaidEnabled: company?.isGetPaidEnabled,
    isPropertyEnabled: company?.isPropertyEnabled,
    isStudioEnabled: company?.isStudioEnabled,
    featuresSort: company?.featuresSort,
    isTodoTaskEnabled: company?.isTodoTaskEnabled
  }
  SetAlreadyLaunchedIntercom(false);
  settingUpSentry(user);
  localStorageItem("token", token);
  localStorageItem("role", JSON.stringify(role));
  localStorageItem("company", JSON.stringify(company));

  localStorageItem("activatedFeatures", JSON.stringify(activatedStatus));
  localStorageItem("user", JSON.stringify(user));
  //
  localStorageItem("lastApiCallTimestamp", new Date());
};

export const timeAgo = (date) => {
  const currentDate = new Date();
  const adjustedCurrentDate = new Date(currentDate.getTime() - (5 * 60 * 60 * 1000));

  const previousDate = new Date(date);

  const seconds = Math.floor((adjustedCurrentDate - previousDate) / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);
  const years = Math.floor(days / 365);

  if (years > 0) {
    return years === 1 ? "1 year ago" : `${years} years ago`;
  } else if (months > 0) {
    return months === 1 ? "1 month ago" : `${months} months ago`;
  } else if (days > 0) {
    return days === 1 ? "1 day ago" : `${days} days ago`;
  } else if (hours > 0) {
    return hours === 1 ? "1 hour ago" : `${hours} hours ago`;
  } else if (minutes > 0) {
    return minutes === 1 ? "1 minute ago" : `${minutes} minutes ago`;
  } else {
    return seconds <= 1 ? "just now" : `${seconds} seconds ago`;
  }
}


export const settingUpSentry = (user) => {
  Sentry?.setUser({ id: user?.userid, email: user?.email });
  Sentry.init({
    dsn: "https://3134abe1921eedba513af4a7e1238820@o4505889664008192.ingest.sentry.io/4505889670692864",
    integrations: [
      new Sentry.BrowserTracing()
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0,
    // Session Replay
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
  });
}

export const getCompanyData = (history) => {
  return JSON.parse(localStorage.getItem("company") || {});
};

export const getCompanyDataValue = (property) => {
  return JSON.parse(localStorage.getItem("company") || {})[property];
};

export const getCompanyIsStandardStripe = () => {
  return getCompanyDataValue('stripeAccountType') == 'standard';
};

export const CheckIfEnabledInStripeAccountType = (node, stripeAccountType) => {
  if (node && node.stripeAccountTypes && stripeAccountType) {
    if (node.stripeAccountTypes && node.stripeAccountTypes.length == 0) {
      return true;
    }
    if (node.stripeAccountTypes && node.stripeAccountTypes.find(r => r.toUpperCase() == stripeAccountType.toUpperCase())) {
      return true;
    }
  }
  return false;
}

export const logoutHandler = (history) => {
  const darkModeValue = localStorage.getItem("darkMode");
  Sentry.setUser(null);
  Sentry.close();
  localStorage.clear();
  localStorageItem("darkMode", darkModeValue);
  history && history.push("/");
  window.Intercom("shutdown");
  SetAlreadyLaunchedIntercom(false);
};

export const switchCompanyHandler = (history) => {
  history && history.push("/multi-company");
};

export const trackIntercomEvent = (eventName, metadata) => {
  try {
    window.Intercom("trackEvent", eventName, metadata);
  } catch { }
};

export const getCurrencyFromLocalStorage = () => {
  let currency = "$";
  if (typeof window !== "undefined") {
    currency = localStorage.getItem("currencySymbol");
  }
  return currency;
};

export const getStripeReturnURL = () => {
  return (
    window?.location?.protocol +
    "//" +
    window?.location?.host +
    "/dashboard?showBanner=true"
  );
};

export const getResetPasswrodReturnUrl = () => {
  return (
    window?.location?.protocol +
    "//" +
    window?.location?.host +
    "/resetPassword"
  );
};

export const getReturnUrl = (postfix) => {
  return (
    window?.location?.protocol + "//" + window?.location?.host + "/" + postfix
  );
};

export const dateFormatter = (inputDate, toLocalDate) => {
  var options = {
    timeZone: "UTC",
    month: "short",
    day: "2-digit",
    year: "numeric",
  };
  if (toLocalDate) options["timeZone"] = undefined;
  return new Date(inputDate).toLocaleDateString({}, options);
};

export const dateFormatterWithTime = (inputDate, toLocalTime) => {
  var options = {
    timeZone: "UTC",
    month: "short",
    day: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };
  if (toLocalTime) options["timeZone"] = undefined;
  return new Date(inputDate).toLocaleDateString({}, options);
};

export const convertUTCToLocalTime = (dateString) => {
  let date = new Date(dateString);
  const milliseconds = Date.UTC(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds()
  );
  const localTime = new Date(milliseconds);
  return localTime;
};

export const getRRuleStartDateString = (date) => {
  var dtstart =
    String(date.getFullYear()).padStart(4, '0') +
    String(date.getMonth() + 1).padStart(2, '0') +
    String(date.getDate()).padStart(2, '0') + 'T' +
    String(date.getHours()).padStart(2, '0') +
    String(date.getMinutes()).padStart(2, '0') + '00Z';
  return dtstart;
};

export const dateFormatterUTC = (inputDate) => {
  if (!inputDate) return "";
  return new Date(inputDate).toLocaleDateString(
    {},
    { month: "short", day: "2-digit", year: "numeric" }
  );
};

export const GetFileNameFromPath = (filePath, splitName) => {
  if (!filePath)
    return null;

  let lastIndexOfSlash = filePath.lastIndexOf("/");
  let fileNameWithExtension = decodeURI(
    filePath.substr(lastIndexOfSlash + 1, filePath.length)
  );
  let fileNameSplit = decodeURI(fileNameWithExtension).split(".");
  let fileName = fileNameSplit[0];
  let fileNameExtension = "";
  if (fileNameSplit.length > 0) {
    fileNameExtension = fileNameSplit[fileNameSplit.length - 1];
  }
  if (fileNameExtension) {
    fileNameExtension = fileNameExtension.split("?")[0].toLowerCase();
  }
  if (splitName && fileName) {
    fileName = fileName.split("-")[0];
  }
  return {
    fileName: fileName,
    fullFileName: fileNameWithExtension,
    extension: fileNameExtension,
  };
};

export const dateFormatterWithTimeZone = (inputDate) => {
  if (!inputDate) return inputDate;

  var d = new Date(inputDate);
  var options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    seconds: "2-digit",
    timeZoneName: "short",
  };
  return d.toLocaleDateString("en-US", options);
};

export const isAccountingEnabled = () => {
  let isAccountingEnabled =
    localStorage.getItem("isAccountingEnabled") === "true" ? true : false;
  return isAccountingEnabled;
};

export const base64toBlob = (base64Data, contentType) => {
  contentType = contentType || "";
  var sliceSize = 1024;
  var byteCharacters = atob(base64Data);
  var bytesLength = byteCharacters.length;
  var slicesCount = Math.ceil(bytesLength / sliceSize);
  var byteArrays = new Array(slicesCount);

  for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    var begin = sliceIndex * sliceSize;
    var end = Math.min(begin + sliceSize, bytesLength);

    var bytes = new Array(end - begin);
    for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0);
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }
  return new Blob(byteArrays, { type: contentType });
};

export const toTitleCase = (str) => {
  if (!str) return "";

  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const isEmptyString = (str) => {
  return !str || str.length === 0;
};

export const formatCentsAmount = (amount, currency) => {
  if (Number.isNaN(amount)) {
    amount = 0;
  }

  if (amount < 100 && amount > 0) {
    amount = "0.".concat(amount);
  } else {
    amount = amount / 100;
  }

  if (!currency) {
    return amount;
  }

  return new Intl.NumberFormat(undefined, {
    style: "currency",
    currency: currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    currencyDisplay: "narrowSymbol",
  }).format(amount);
};

export const formatStripeAmount = (amount, currencySymbol) => {
  if (currencySymbol == "usd" || currencySymbol == "USD") {
    currencySymbol = "$";
  }
  if (Number.isNaN(amount)) {
    return 0;
  }
  currencySymbol = currencySymbol || "";
  const number = amount / 100;
  const fixed = parseFloat(number).toFixed(2);
  if (fixed == parseInt(number)) {
    return currencySymbol + parseInt(number).toFixed(2);
  }
  return currencySymbol + parseFloat(fixed).toFixed(2);
};


export const formatAmount = (amount, currencySymbol) => {
  currencySymbol = currencySymbol || getCurrencyFromLocalStorage() || "usd";
  if (currencySymbol == "usd" || currencySymbol == "USD") {
    currencySymbol = "$";
  }
  if (Number.isNaN(amount)) {
    amount = 0;
  }
  let number = amount || 0;

  const fixed = parseFloat(number).toFixed(2);
  if (fixed == parseInt(number)) {
    return currencySymbol + parseInt(number).toFixed(2);
  }
  return currencySymbol + parseFloat(fixed).toFixed(2);
};

export const stripeConnectionStatus = () => {
  return localStorage.getItem("stripeConnection") === "true" ? true : false;
};

export const getConnectStatus = () => {
  return JSON?.parse(localStorage?.getItem("connectStatus") || "");
}

export const stripePendingStatus = () => {
  return localStorage.getItem("isPending") === "true" ? true : false;
};

export const stripeTestModeStatus = () => {
  return localStorage.getItem("isTestMode") === "true" ? true : false;
};

export const showAddPayoutBankAccountMessageStatus = () => {
  return localStorage.getItem("showAddPayoutBankAccountMessage") === "true"
    ? true
    : false;
};

export const showFinancialAccountEligibleMessageStatus = () => {
  return localStorage.getItem("showFinancialAccountEligibleMessage") === "true"
    ? true
    : false;
};

export const getCurrentPageName = () => {
  const path = window.location.pathname;
  return path.split("/").pop();
};

export const reverifyHandler = async (setIsLoading) => {
  setIsLoading && setIsLoading(true);
  const reverifyResponse = await reverifyAccount({
    ReturnURL: getStripeReturnURL(),
  });
  const { data } = reverifyResponse;
  if (data) {
    const { redirectURL } = data;
    setTimeout(() => {
      setIsLoading && setIsLoading(false);
    }, 2000);

    window.location = redirectURL;
  }
};

function isValidEmail(email) {
  return /\S+@\S+\.\S+/.test(email);
}

export const emailHandleChange = (event, setError, setEmail) => {
  if (!isValidEmail(event.target.value)) {
    setError("Email is invalid");
  } else if (isValidEmail(event.target.value)) {
    setError(null);
  } else {
    setError(null);
  }
  setEmail(event?.target?.value);
};

export const protectCardRoutingNumbers = (number) => {
  return "****" + number.substr(number.length - 4);
};

export const getBaseURLOfCurrentApp = () => {
  return window?.location?.protocol + "//" + window?.location?.host;
};

export const setCountryFromList = (
  currentValue,
  displayValue,
  setDisplayValue,
  options
) => {
  if (!displayValue) {
    if (currentValue) {
      for (let i = 0; i < options?.length; i++) {
        if (options[i].value === currentValue) {
          setDisplayValue(options[i]);
        }
      }
    }
  }
};

export const toastConfiguration = {
  position: "bottom-right",
  autoClose: 4000,
  theme: "colored",
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

export const doctoastConfiguration = {
  position: "bottom-right",
  autoClose: false,
  // closeButton: false,
  theme: "colored",
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

export const transactionDateFormatter = (date) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-") + "Z";
};

export const CouponRedeemDateFormatter = (date) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export const contractKeyValues = {
  clients_name: "HAMZA",
  clients_first_name: "HAMZA TARIQ",
  clients_phone: "0303030303",
  clients_address: "XYZ",
  clients_email: "hamzatariqdemo@gmail.com",
  link_to_client_portal: "/link",
  current_date: "current_date",
};

//portal

export const getPortalAuthToken = () => {
  return localStorage.getItem("portaltoken");
};

export const handleCustomerPortalLogin = ({
  token,
  expiryOn,
  companyid,
  contact,
  portalSetup,
}) => {
  localStorageItem("portaltoken", token);
  localStorageItem("tokenexpiryon", expiryOn);
  localStorageItem("portalcompanyid", companyid);
  localStorageItem("portalcontact", JSON.stringify(contact));
  localStorageItem("portalportalsetup", JSON.stringify(portalSetup));
};

export const handleCustomerPortalProfile = ({ contact, portalSetup }) => {
  localStorageItem("portalcontact", JSON.stringify(contact));
  localStorageItem("portalportalsetup", JSON.stringify(portalSetup));
};

export const isPortalUserLoggedIn = () => {
  return !!getPortalAuthToken();
};

export const getCurrentUser = () => {
  const user = localStorage?.getItem("user");
  let parsedUser = {};

  if (user) {
    parsedUser = JSON.parse(user);
  }

  return parsedUser;
}

export const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export const getNumberWithSpaces = (x) => {
  if (x) {
    return x?.toFixed(0)?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }
  return x;
}


export const chatDateFormatter = (chatDate) => {
  const date = new Date(chatDate || new Date());

  const options = {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
    hour: 'numeric',
    minute: '2-digit',
  };

  const formattedDate = date.toLocaleDateString('en-US', options);

  const formattedDateTime = `${formattedDate}`;
  return formattedDateTime;
}

export const getChatMemberName = (message) => {
  if (message?.channel?.members) {
    let memberSid = message?.state?.memberSid;
    if (memberSid && message?.channel?.members?.get) {
      let member = message?.channel?.members?.get(memberSid);
      if (member?.state?.attributes) {
        let attributes = member?.state?.attributes;
        if (attributes?.name) {
          return attributes.name;
        }
      }
    }
  }
  return message?.state?.author;
}

export const getChatMemberImageUrl = (message) => {
  if (message?.channel?.members) {
    let memberSid = message?.state?.memberSid;
    if (memberSid && message?.channel?.members?.get) {
      let member = message?.channel?.members?.get(memberSid);
      if (member?.state?.attributes) {
        let attributes = member?.state?.attributes;
        if (attributes?.imageUrl) {
          return attributes.imageUrl;
        }
      }
    }
  }
  return null;
}

export const truncateWithEllipsis = (str, maxLength) => {
  if (str.length <= maxLength) {
    return str;
  } else {
    return str.slice(0, maxLength - 3) + "...";
  }
}

function getOrdinalSuffix(number) {
  if (number === 1 || number === 21 || number === 31) {
    return "st";
  } else if (number === 2 || number === 22) {
    return "nd";
  } else if (number === 3 || number === 23) {
    return "rd";
  } else {
    return "th";
  }
}

export const ordinalDateformatter = (date = new Date()) => {
  const months = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();
  const suffix = getOrdinalSuffix(day);

  return `${day}${suffix} ${months[monthIndex]}, ${year}`;
}

export const makeAValidUrl = (url) => {
  if (!!url && !/^https?:\/\//i.test(url)) {
    url = 'https://' + url;
  }

  return url
}

export const prepareAccountForOverview = (accountsFromApi, defCurrency) => {
  if (!accountsFromApi || !defCurrency) return accountsFromApi;

  if (accountsFromApi?.length === 2) {
    accountsFromApi.shift();
  }

  defCurrency = defCurrency.toLowerCase();
  var allBalance = 0;
  var allAccountIdx = -1;
  for (let index = 0; index < accountsFromApi.length; ++index) {
    const accountInfo = accountsFromApi[index];
    if (accountInfo.id === "All") {
      allAccountIdx = index;
      continue;
    }

    if (accountInfo.balances && accountInfo.balances[defCurrency]) {
      accountInfo.displayBalance = accountInfo.balances[defCurrency];
      allBalance += accountInfo.balances[defCurrency];
    } else {
      accountInfo.displayBalance = 0;
    }
  }

  if (allAccountIdx >= 0) {
    accountsFromApi[allAccountIdx].displayBalance = allBalance;
  }
  return accountsFromApi;
};