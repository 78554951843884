import React, { useEffect, useRef, useState } from "react";
import Dropdown from "./../../../components/Dropdown";
import Card from "../../../components/Card";
import cn from "classnames";
import styles from "./DataLayersSection.module.sass";
import { getLayer } from "./../layerjs";
import { getDataLayerUrls, findClosestBuilding } from "./../solarjs";
import Slider from "react-input-slider";

const sliderStyles = {
  track: {
    marginTop: 18,
    backgroundColor: "#FF4900",
    width: "100%",
  },
  active: {
    backgroundColor: "#FF4900",
  },
};

const google = window.google;
//const geometryLibrary = window.google.maps.GeometryLibrary;

const DataLayersSection = ({
  location,
  showRoofOnly,
  placesLibrary,
  geometryLibrary,
  map,
  initialValue = "",
  zoom = 19,
}) => {
  const dataLayerOptions = {
    none: "No layer",
    //mask: "Roof mask",
    //dsm: "Digital Surface Model",
    //rgb: "Aerial image",
    annualFlux: "Annual sunshine",
    monthlyFlux: "Monthly sunshine",
    //hourlyShade: "Hourly shade",
  };

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  //const [layer, setLayer] = useState("");

  useEffect(() => {}, []);

  const [overlays, setOverlays] = useState([]);
  //const [showRoofOnly, setShowRoofOnly] = useState(false);
  const [dataLayersResponse, setDataLayersResponse] = useState(undefined);
  const [requestError, setRequestError] = useState(undefined);
  const [layer, setLayer] = useState(undefined);
  const [month, setMonth] = useState(0);
  const [day, setDay] = useState(14);
  const [hour, setHour] = useState(5);
  const [playAnimation, setPlayAnimation] = useState(false);

  const [layerId, setLayerId] = useState("none"); 

  const [buildingInsights, setBuildingInsights] = useState(undefined);

  let googleMapsApiKey = "AIzaSyCv1RM9Dm8URu-rZ-yl2XwTf2H20jGphqY";

  let monthCounter = 0;

  useEffect(() => {
    if (layer?.id == "monthlyFlux") {
      overlays.map((overlay, i) => overlay.setMap(i == month ? map : null));
    } else if (layer?.id == "hourlyShade") {
      overlays.map((overlay, i) => overlay.setMap(i == hour ? map : null));
    }
  }, [month]);

  useEffect(() => {
    showDataLayer();
  }, [layerId, location, showRoofOnly]);

  function showNumber(x) {
    return x.toLocaleString(undefined, { maximumFractionDigits: 1 });
  }

  const onSliderChange = (x) => {
    if (x == monthCounter) return;
    monthCounter = x;
    console.log(x);
    setMonth(x);
  };

  const showDataLayer = async (reset = false) => {
    if (reset) {
      setDataLayersResponse(undefined);
      setRequestError(undefined);
      setLayer(undefined);

      //setShowRoofOnly(['annualFlux', 'monthlyFlux', 'hourlyShade'].includes(layerId));
      map.setMapTypeId(layerId === "rgb" ? "roadmap" : "satellite");
      overlays.forEach((overlay) => overlay.setMap(null));
      setMonth(layerId === "hourlyShade" ? 3 : 0);
      setPlayAnimation(["monthlyFlux", "hourlyShade"].includes(layerId));
    }

    var buildingInsightsResp = null;
    //if (!buildingInsights)
    //{
    buildingInsightsResp = await findClosestBuilding(
      location,
      googleMapsApiKey
    );
    setBuildingInsights(buildingInsightsResp);
    //}

    if (layerId === "none") {
      return;
    }

    if (true || !layer) {
      const center = buildingInsightsResp.center;
      const ne = buildingInsightsResp.boundingBox.ne;
      const sw = buildingInsightsResp.boundingBox.sw;
      const diameter = geometryLibrary.spherical.computeDistanceBetween(
        new google.maps.LatLng(ne.latitude, ne.longitude),
        new google.maps.LatLng(sw.latitude, sw.longitude)
      );
      const radius = Math.ceil(diameter / 2);

      var dataLayersResp = null;
      try {
        dataLayersResp = await getDataLayerUrls(
          center,
          radius,
          googleMapsApiKey
        );
        setDataLayersResponse(dataLayersResp);
      } catch (e) {
        setRequestError(e);
        return;
      }

      //const imageryQuality = dataLayersResponse.imageryQuality;

      var layerResp = null;
      try {
        layerResp = await getLayer(layerId, dataLayersResp, googleMapsApiKey);
        setLayer(layerResp);
      } catch (e) {
        setRequestError(e);
        return;
      }
    }

    const bounds = layerResp.bounds;
    console.log("Render layer:", {
      layerId: layerResp.id,
      showRoofOnly: showRoofOnly,
      month: month,
      day: day,
    });
    overlays.forEach((overlay) => overlay.setMap(null));
    const newOverlays = layerResp
      .render(showRoofOnly, month, day)
      .map(
        (canvas) => new google.maps.GroundOverlay(canvas.toDataURL(), bounds)
      );

    setOverlays(newOverlays);

    //if (!["monthlyFlux", "hourlyShade"].includes(layerResp.id)) {
    if (!["hourlyShade"].includes(layerResp.id)) {
      newOverlays[0].setMap(map);
    }
  };

  return (
    <>
      <div className={styles.row}>

        <div className={styles.row}>
          <div className={styles.col}>
            <div className={styles.field}>
              {buildingInsights ? (
                <Card
                  className={cn(styles.card)}
                  classCardHead={styles.head}
                  title={
                    <>
                      <span>Building Insights</span>
                    </>
                  }
                >
                  <div className={styles.colWrap}>
                    <div className={styles.flex}>
                      <p>Annual sunshine</p>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: 5,
                        }}
                      >
                        {showNumber(
                          buildingInsights.solarPotential
                            .maxSunshineHoursPerYear
                        )}
                        hr
                      </div>
                    </div>
                    <div className={styles.flex}>
                      <p>Roof area</p>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: 5,
                        }}
                      >
                        {showNumber(
                          buildingInsights.solarPotential.wholeRoofStats
                            .areaMeters2
                        )}
                        m²
                      </div>
                    </div>
                    <div className={styles.flex}>
                      <p>Max panel count</p>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: 5,
                        }}
                      >
                        {showNumber(
                          buildingInsights.solarPotential.solarPanels.length
                        )} 
                        panels
                      </div>
                    </div>
                    <div className={styles.flex}>
                      <p>CO₂ savings</p>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: 5,
                        }}
                      >
                        {showNumber(
                          buildingInsights.solarPotential
                            .carbonOffsetFactorKgPerMwh
                        )}
                        Kg/MWh
                      </div>
                    </div>
                  </div>
                </Card>
              ) : null}
            </div>
          </div>
        </div>
        <div className={styles.row} style={{ margin: "0px" }}>
          <div className={styles.col}>
            <div className={styles.field}>
              <div className={styles.fieldLabel}>Layer:</div>
              <Dropdown
                value={layerId}
                setValue={setLayerId}

                //options={Object.keys(dataLayerOptions).map(value => dataLayerOptions[value])}
                options={Object.keys(dataLayerOptions)}
                onChange={(value) => {
                  setLayer(undefined);
                  //showDataLayer(value, false);
                }}
                // handleChange={(value, item) => {
                //   debugger;
                //   setLayer(undefined);
                //   setLayerIdStr(value);
                //   setLayerId(value);

                // }}
              />
            </div>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.col}>
            {layerId === "monthlyFlux" ? (
              <>
                <div className={styles.fieldLabel}>Month:</div>
                <Slider
                  axis="x"
                  xmin={0}
                  xmax={11}
                  styles={sliderStyles}
                  x={month}
                  onChange={({ x }) => onSliderChange(x)}
                />
                {monthNames[month]}
              </>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default DataLayersSection;
