import cn from "classnames";
import withUserAuth from "../../utils/withUserAuth";
import Spinner from "../../utils/spinner";
import ErrorModal from "../../components/ErrorModal";
import { useParams, withRouter } from "react-router";
import React, { useEffect, useRef, useState } from 'react';
//import { Loader } from '@googlemaps/js-api-loader';
import SearchBar from './SearchBar';
//import Sections from './sections/Sections';
import { Loader } from '@googlemaps/js-api-loader';
import DataLayersSection from "./DataLayersSection";
import styles from "./Solar.module.sass";
import Card from "../../components/Card";
import Checkbox from "../../components/Checkbox";


const google = window.google;

const Solar = (props) => {

  // const defaultPlace = {
  //   name: 'Rinconada Library',
  //   address: '1213 Newell Rd, Palo Alto, CA 94303',
  // };
  
  const defaultPlace = {
    name: 'Avenida de las Palmas, 7, Marbella, Spain',
    address: 'Avenida de las Palmas, 7, Marbella, Spain',
  };

//   const loader = new Loader({
//     apiKey: "YOUR_API_KEY",
//     version: "weekly",
//     libraries: ["places"]
// });

const mapElement = useRef(null);
const [location, setLocation] = useState(undefined);
const [map, setMap] = useState(null);
const [geometryLibrary, setGeometryLibrary] = useState(null);
const [mapsLibrary, setMapsLibrary] = useState(null);
const [placesLibrary, setPlacesLibrary] = useState(null);
const zoom = 19;
const [roofOnly, setRoofOnly] = useState(true);


useEffect(() => {
  const loader = new Loader({ apiKey: "AIzaSyCv1RM9Dm8URu-rZ-yl2XwTf2H20jGphqY" });
  const loadGoogleMaps = async () => {
    const geometry = await loader.importLibrary('geometry');
    const maps = await loader.importLibrary('maps');
    const places = await loader.importLibrary('places');
    setGeometryLibrary(geometry);
    setMapsLibrary(maps);
    setPlacesLibrary(places);

    const geocoder = new google.maps.Geocoder();
    const geocoderResponse = await geocoder.geocode({
      address: defaultPlace.address,
    });
    const geocoderResult = geocoderResponse.results[0];
    setLocation(geocoderResult.geometry.location);
    const newMap = new maps.Map(mapElement.current, {
      center: geocoderResult.geometry.location,
      zoom: zoom,
      tilt: 0,
      mapTypeId: 'satellite',
      mapTypeControl: false,
      fullscreenControl: false,
      rotateControl: false,
      streetViewControl: false,
      zoomControl: false,
    });
    setMap(newMap);
  };

  loadGoogleMaps();
}, []);


  return (
    <>
            {/* <div
                ref={(ref) => { this.googleMapDiv = ref }}
                style={{ height: '100vh', width: '100%' }}>
            </div> */}
    <Card
          className={styles.card}
          title={'Solar'}
        >
      <aside className="flex-none md:w-96 w-80 p-2 pt-3 overflow-auto">
        <div className="flex flex-col space-y-2 h-full">
          {placesLibrary && map && (
            <>

              <div className={styles.row}>
                <div className={styles.row} style={{ margin: "0px" }}>
                  <div className={styles.col}>
                    <div className={styles.field}>
                      <div className={styles.fieldLabel}>Address:</div>
                      <SearchBar location={location} setLocation={setLocation} placesLibrary={placesLibrary} map={map} initialValue={defaultPlace.name} zoom={zoom} />
                    </div>
                  </div>
                  <div className={styles.col}>
                    <div className={styles.field}>
                      {/* <DataLayersSection location={location} showRoofOnly={roofOnly} placesLibrary={placesLibrary} geometryLibrary={geometryLibrary} map={map} initialValue={defaultPlace.name} zoom={zoom} /> */}
                    </div>
                  </div>
                </div>
                <div className={styles.row}>
                  <DataLayersSection location={location} showRoofOnly={roofOnly} placesLibrary={placesLibrary} geometryLibrary={geometryLibrary} map={map} initialValue={defaultPlace.name} zoom={zoom} />
                </div>
                <div className={styles.row}>
                  <div className={styles.col}>
                    <div className={styles.field}>
                    <Checkbox
                          className={styles.checkbox}
                          classCheckboxTick={styles.checkboxTick}
                          value={roofOnly}
                          onChange={() => setRoofOnly(!roofOnly)}
                          content={"Roof only"}
                        />
                        {/* <span>Roof only</span> */}
                    </div>
                  </div>
                </div>
              </div>

            </>
          )}


          {/* {location && <Sections location={location} map={map} geometryLibrary={geometryLibrary} googleMapsApiKey={googleMapsApiKey} />} */}

          <div className="grow" />
        </div>
      </aside>
      <div ref={mapElement} style={{ height: '100vh', width: '100%' }}/>
    </Card>
    </>
  );
};

export default withRouter(Solar);
