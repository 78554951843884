import React, { useState, useRef } from "react";
import cn from "classnames";
import styles from "./Overview.module.sass";
import Item from "./Item";
import Card from "../../../components/Card";
import Chart from "./Chart";
import Switch from '../../../components/Switch';
import {
  toastConfiguration,
  transactionDateFormatter,
} from "../../../utils/utils";
import {
  getReportsOverview,
} from "../../../utils/apiCallHanlder";
import Spinner from "../../../utils/spinner";
import "react-multi-carousel/lib/styles.css";
import { useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import LargeNav from "../../../components/LargeNav";
import Income from "./Income";
import Expense from "./Expense";
import CashOnHand from '../../Home/Overview'

const nav = [
  {
    title: "Income",
    subTitle: "",
    counter: "$200.00",
    value: 37.8,
  },
  {
    title: "Expense",
    subTitle: "",
    counter: "$300.00",
    value: 47.8,
  },
  {
    title: "Taxes",
    subTitle: "",
    counter: "$100.00",
    value: 37.8,
  },
  {
    title: "Profit",
    subTitle: "",
    counter: "$300.00",
    value: -37.8,
  },

];

const filters = ["All Time", "This Quarter", "Previous Quarter", "This Year", "Previous Year"]

const Overview = ({
  className,
  graph,
  setGraph,
  isDashboardView,
  showOverviewOnly,
  reportsData,
  loadingForDetailData,
  overviewAccounts,
  transactions,
  cashOnHandGraph,
  setCashOnHandGraph,
  onDateRangeChanged,
  defCurrencyCode,
}) => {
  const targetRef = useRef();
  const [activeIncome, setActiveIncome] = useState(true);
  const [activeExpense, setActiveExpense] = useState(true);
  const [activeProfit, setActiveProfit] = useState(true);
  const [fetchingGraphData, setFetchingGraphData] = useState(false);
  const [comparedWithPrevYear, setCompareWithPrevYear] = useState(true);
  const [activeFilter, setActiveFilter] = useState(3);
  const [allTimeGraphData, SetAllTimeGraphData] = useState({});
  const [byWhoData, setByWhoData] = useState({});
  const [byCategoryData, setByCategoryData] = useState({});
  const [yearsData, setYearsData] = useState({});


  useEffect(async () => {
    const date = new Date();
    await getReportsOverviewForMainpage(date, date);
  }, []);

  const getReportsOverviewForMainpage = async (start, end) => {
    if (start) {
      setFetchingGraphData(true);
      let data = null;
      let error = '';

      if (reportsData) {
        data = reportsData;
      } else {
        const dbReportsData = await getReportsOverview({
          fromDate: transactionDateFormatter(start),
          toDate: transactionDateFormatter(end),
        });
        data = dbReportsData?.data;
        error = dbReportsData?.error;
      }

      if (data) {
        const report = data?.report;
        const key = Object?.keys(report?.byYear)[1];
        const yearData = report?.byYear[key];

        const computedGraphData = Object.keys(report?.graphData)?.map(years => {
          const currentYear = report?.graphData[years][key];
          if (currentYear) {
            return currentYear;
          }
        })

        SetAllTimeGraphData(report?.graphData);
        setByWhoData(report?.byWho)
        setByCategoryData(report?.byCategory)
        setGraph(computedGraphData);
        setYearsData(report?.byYear)

        nav[0].counter = `$${parseFloat(yearData?.revenue).toFixed(2)}`;
        nav[0].subTitle = yearData?.revenueComment;
        nav[1].counter = `$${parseFloat(yearData?.expense).toFixed(2)}`;
        nav[1].subTitle = yearData?.expenseComment;
        nav[3].counter = `$${parseFloat(yearData?.profit).toFixed(2)}`;
        nav[3].subTitle = yearData?.profitComment;
      } else {
        toast.error(error, toastConfiguration);
      }
      setFetchingGraphData(false);
    }
  };


  return (
    <div style={fetchingGraphData ? { pointerEvents: "none" } : isDashboardView ? { marginTop: 24 } : null}>
      <ToastContainer />
      {(showOverviewOnly || !isDashboardView) &&
        <>
          <Card
            className={cn(styles.card, className)}
            title={
              <>
                {isDashboardView && (
                  <span style={{ color: '#464542' }}>
                    {`Overview`}
                  </span>
                )}
                <div style={{ marginTop: -16, display: 'flex', alignItems: 'center' }}>
                  <LargeNav
                    className={styles.typeNav}
                    tabOptions={filters}
                    activeTab={activeFilter}
                    onClick={(x, index) => {
                      setActiveFilter(index);
                      let today = new Date();
                      let quarter = Math.floor((today.getMonth() + 3) / 3);
                      let arrayToSet = [];

                      if (index !== 4) {
                        const yearData = yearsData[today.getFullYear()];
                        nav[0].counter = `$${parseFloat(yearData?.revenue).toFixed(2)}`;
                        nav[0].subTitle = yearData?.revenueComment;
                        nav[1].counter = `$${parseFloat(yearData?.expense).toFixed(2)}`;
                        nav[1].subTitle = yearData?.expenseComment;
                        nav[3].counter = `$${parseFloat(yearData?.profit).toFixed(2)}`;
                        nav[3].subTitle = yearData?.profitComment;
                      }

                      if (index === 0 || index === 3) {
                        Object.keys(allTimeGraphData)?.map(years => {
                          const key = Object?.keys(allTimeGraphData[years])[1];
                          arrayToSet.push(allTimeGraphData[years][key]);
                        })

                        setGraph(arrayToSet);
                      } else if (index === 1) {
                        Object?.keys(allTimeGraphData || {})?.slice((quarter - 1) * 3, quarter * 3).map(item => {
                          const key = Object?.keys(allTimeGraphData[item])[1];
                          arrayToSet.push(allTimeGraphData[item][key]);
                        })

                        setGraph(arrayToSet);
                      } else if (index === 2) {
                        quarter = quarter - 1;
                        if (quarter === 0) {
                          quarter = 4;
                        }

                        Object?.keys(allTimeGraphData || {})?.slice((quarter - 1) * 3, quarter * 3).map(item => {
                          let key = Object?.keys(allTimeGraphData[item])[1];
                          if (quarter === 4) {
                            key = key - 1;
                          }
                          arrayToSet.push(allTimeGraphData[item][key]);
                        })

                        setGraph(arrayToSet);
                      } else {
                        Object?.keys(allTimeGraphData || {}).map(item => {
                          let key = Object?.keys(allTimeGraphData[item])[0];
                          arrayToSet.push(allTimeGraphData[item][key]);
                        })

                        setGraph(arrayToSet);

                        //For year boxes
                        const yearData = yearsData[today.getFullYear() - 1];
                        nav[0].counter = `$${parseFloat(yearData?.revenue).toFixed(2)}`;
                        nav[0].subTitle = yearData?.revenueComment;
                        nav[1].counter = `$${parseFloat(yearData?.expense).toFixed(2)}`;
                        nav[1].subTitle = yearData?.expenseComment;
                        nav[3].counter = `$${parseFloat(yearData?.profit).toFixed(2)}`;
                        nav[3].subTitle = yearData?.profitComment;
                      }
                    }}
                  />
                </div>
                {/* <div style={{ display: 'flex', alignItems: 'center' }}>
              <Switch
                className={styles.switchStyle}
                value={comparedWithPrevYear}
                onChange={() => {
                  setCompareWithPrevYear(!comparedWithPrevYear);
                }
                }
              />
              <span style={{ marginLeft: 8, fontSize: 18 }}>
                Compare vs same previous period
              </span>
            </div> */}
              </>
            }
            classCardHead={styles.cardHead}
          >
            <div id={'carouselWrap'} ref={targetRef}>
              {fetchingGraphData ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Spinner size="44" color="gray" />
                </div>
              ) : (
                <div className={styles.overview}>
                  <div
                    className={cn(styles.nav, {
                      [styles.chartShow]:
                        activeIncome ||
                        activeExpense ||
                        activeProfit
                    })}
                  >
                    <div className={styles.ItemContainer}>
                      <Item
                        className={cn(styles.item, {
                          [styles.activeItem0]: activeIncome,
                        })}
                        onActive={() => {
                          if (activeProfit || activeExpense) {
                            setActiveIncome(!activeIncome)
                          }
                        }}
                        showSubtitle={comparedWithPrevYear}
                        item={nav[0]}
                        currentFilter={activeFilter}
                      />
                      <Item
                        className={cn(styles.item, {
                          [styles.activeItem1]: activeExpense,
                        })}
                        onActive={() => {
                          if (activeIncome || activeProfit) {
                            setActiveExpense(!activeExpense)
                          }
                        }}
                        showSubtitle={comparedWithPrevYear}
                        item={nav[1]}
                        currentFilter={activeFilter}
                      />
                      <Item
                        className={cn(styles.item, {
                          [styles.activeItem2]: activeProfit,
                        })}
                        onActive={() => {
                          if (activeIncome || activeExpense) {
                            setActiveProfit(!activeProfit)
                          }
                        }}
                        showSubtitle={comparedWithPrevYear}
                        item={nav[3]}
                        currentFilter={activeFilter}
                      />
                    </div>
                  </div>
                  <div className={styles.body}>
                    {!activeIncome &&
                      !activeExpense &&
                      !activeProfit ? null : (
                      <Chart
                        activeIncome={activeIncome}
                        activeExpense={activeExpense}
                        activeProfit={activeProfit}
                        data={graph}
                        comparedWithPrevYear={comparedWithPrevYear}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          </Card>
          {overviewAccounts?.length > 0 && (
            <div className={styles.col12} style={{ marginTop: 24, marginBottom: 6 }}>
              <CashOnHand
                className={styles.cardX}
                loadingForDetailData={loadingForDetailData}
                accounts={overviewAccounts}
                transactions={transactions}
                graph={cashOnHandGraph}
                setGraph={setCashOnHandGraph}
                onDateRangeChanged={onDateRangeChanged}
                defCurrencyCode={defCurrencyCode}
              />
            </div>
          )}
        </>
      }
      {(!showOverviewOnly || !isDashboardView) &&
        <div className={isDashboardView && styles.incomeExpenseContainer}>
          <div className={isDashboardView && styles.incomeContainer}>
            <Income
              data={byWhoData}
              categoryData={byCategoryData}
              currentFilter={activeFilter}
              isDashbord={isDashboardView}
            />
          </div>

          <div className={isDashboardView && styles.expenseContainer}>
            <Expense
              data={byWhoData}
              categoryData={byCategoryData}
              currentFilter={activeFilter}
              isDashbord={isDashboardView}
            />
          </div>
        </div>
      }
    </div>
  );
};

export default Overview;
